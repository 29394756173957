import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function EstadoCuenta() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [mailTo, setMailTo] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldo, setSaldo] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalCargos, setModalCargos] = useState(false);
  const toggleCargos = () => setModalCargos(!modalCargos);
  const [modalRelacionado, setModalRelacionado] = useState(false);
  const toggleRelacionado = () => setModalRelacionado(!modalRelacionado);
  const [saldoFiltroRel, setSaldoFiltroRel] = useState("Pendientes");

  const [activo, setActivo] = useState("Si");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [movimientos, setMovimientos] = useState([])
  const [idCliente, setIdCliente] = useState("");
  const [saldoInicial, setSaldoInicial] = useState(0);

  const [cargos, setCargos] = useState([]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;


  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre_comercial: a.nombre_comercial,
                razon_social: a.razon_social,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
                cliente:  a.nombre_comercial,
                ramo: a.ramo
              }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setClientes(allClientes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
     
      let SI = movimientos.map((c)=>{
        if(selectedFechaInicio){
          if(
          (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)){
            return (
                c.cargo - c.abono
            )
          }
        }
        else{
          return 0
        }
      }).filter(function (el) {
        return el != null;
      });

      let TC = SI.reduce((t, total, index) => t + total, 0);

      setSaldoInicial(TC)

  }, [user, movimientos, selectedFechaInicio]);


  const ListaClientes = comments.sort((a, b) =>
    a.nombre_comercial > b.nombre_comercial ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          "$" + new Intl.NumberFormat("en-US").format(a.cargos),
          "$" + new Intl.NumberFormat("en-US").format(a.abonos),
          "$" + new Intl.NumberFormat("en-US").format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [["Cliente", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuenta.pdf`);
  }

  function excel() {
    const dataExcel = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Cliente: a.nombre_comercial,
          Cargos: a.cargos,
          Abonos: a.abonos,
          Saldo: a.saldo
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: ["Cliente", "Cargos", "Abonos", "Saldo"],
        sheetHeader: ["Cliente", "Cargos", "Abonos", "Saldo"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaClientes.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre_comercial,
          "$" + new Intl.NumberFormat("en-US").format(a.cargos),
          "$" + new Intl.NumberFormat("en-US").format(a.abonos),
          "$" + new Intl.NumberFormat("en-US").format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [["Cliente","Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }



  const headers = [
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Ramo", field: "ramo", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial
            .toLowerCase()
            .includes(search.toLowerCase()) ||
            comment.ramo.toLowerCase().includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search)||
          comment.abonos.toString().includes(search)||
          comment.saldo.toString().includes(search)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

//Sorting comments
if (
  sorting.field &&
  sorting.field != "cargos" &&
  sorting.field != "abonos" &&
  sorting.field != "saldo"
) {
  const reversed = sorting.order === "asc" ? 1 : -1;
  computedComments = computedComments.sort(
    (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  );
}

if (
  sorting.field &&
  sorting.order === "asc" &&
  (sorting.field == "cargos" ||
    sorting.field == "abonos" ||
    sorting.field == "saldo")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
  );
}

if (
  sorting.field &&
  sorting.order === "desc" &&
  (sorting.field == "cargos" ||
  sorting.field == "abonos" ||
    sorting.field == "saldo")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
  );
}

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

 async function jalaMov(id, cliente, saldo){
    await  axios
      .get(`${URL_ESTADO_CUENTA}/${id}`, {
        headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allVentas = response.data
      setMovimientos(allVentas);
      //
    })
    .catch((err) => {
      console.log(err);
    });
      setNombreCliente(cliente)
      setIdCliente(id)
      setSaldo(saldo)
      toggle()
  }

  async function jalaCargos(id, cliente, saldo){
    await  axios
      .get(`${URL_CARGOS}/cliente/${id}`, {
        headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allVentas = response.data
      setCargos(allVentas);
      //
    })
    .catch((err) => {
      console.log(err);
    });
      setNombreCliente(cliente)
      setIdCliente(id)
      setSaldo(saldo)
      toggleCargos()
  }

  // PDF Mail Excel Cliente
    let saldoParcial = saldoInicial
    let totalCargosEdoCte = 0
    let totalAbonosEdoCte = 0
    let totalSaldoEdoCte = 0

  function PDFOC() {
    let saldoParcial = saldoInicial
    let totalCargosEdoCte = 0
    let totalAbonosEdoCte = 0
    let totalSaldoEdoCte = 0
    const data = movimientos
      .map((a) => {
        if(
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)){
          totalCargosEdoCte = totalCargosEdoCte + a.cargo
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo
          saldoParcial = saldoParcial + a.cargo - a.abono
      const cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargo);
      const abonos = "$" + new Intl.NumberFormat("en-US").format(a.abono);
      var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
      return [a.fecha, a.movimiento, cargos, abonos, saldoParcialFormato];
    }
  })
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 137, 23, 60, 20);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 160, 23, 25, 25);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`,15,30);
    doc.text(`Cliente ${nombreCliente}`,15,37);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          { content: 'Saldo Anterior', colSpan: 2, styles: { halign: 'left' } },
          // "Saldo Anterior",
          "$" + new Intl.NumberFormat("en-US").format(saldoInicial),
        ],
        [
          "",
          "",
          { content: 'Cargos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          { content: 'Abonos del Periodo', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          { content: 'Saldo Actual', colSpan: 2, styles: { halign: 'left' } },
          "$" + new Intl.NumberFormat("en-US").format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.setFontSize(10);
    doc.text(10, doc.autoTable.previous.finalY + 10, "Datos Bancarios");
    doc.text(10, doc.autoTable.previous.finalY + 15, "Banorte");
    doc.text(10, doc.autoTable.previous.finalY + 20, "Nombre: Cristian Omar Perez Sedas");
    doc.text(10, doc.autoTable.previous.finalY + 25, "Clabe: 072180012996298134");
    doc.text(10, doc.autoTable.previous.finalY + 30, "Cuenta: 01299629813");
    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
  }

  function emailFOC() {
    let saldoParcial = saldoInicial
    let totalCargosEdoCte = 0
    let totalAbonosEdoCte = 0
    let totalSaldoEdoCte = 0
    const data = movimientos
    .map((a) => {
      if(
      (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)){
        totalCargosEdoCte = totalCargosEdoCte + a.cargo
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo
        saldoParcial = saldoParcial + a.cargo - a.abono
    const cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargo);
    const abonos = "$" + new Intl.NumberFormat("en-US").format(a.abono);
    var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
    return [a.fecha, a.movimiento, cargos, abonos, saldoParcialFormato];
  }
})
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF();
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 137, 23, 60, 20);
  // var img2 = new Image();
  // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  // doc.addImage(img2, "png", 160, 23, 25, 25);
  // doc.setFontSize(14)
  // doc.setFontSize(14)
  doc.text(`Estado de Cuenta`,15,30);
  doc.text(`Cliente ${nombreCliente}`,15,37);
  doc.text(`Desde ${selectedFechaInicio}`,15,44);
  doc.autoTable({
    head: [["Fecha", "Movimiento", "Cargos", "Abonos", "Saldo"]],
    body: dataPDFLimpia,
    startY: 50,
    foot: [
      [
        "",
        "",
        { content: 'Saldo Anterior', colSpan: 2, styles: { halign: 'left' } },
        // "Saldo Anterior",
        "$" + new Intl.NumberFormat("en-US").format(saldoInicial),
      ],
      [
        "",
        "",
        { content: 'Cargos del Periodo', colSpan: 2, styles: { halign: 'left' } },
        "$" + new Intl.NumberFormat("en-US").format(totalCargosEdoCte),
      ],
      [
        "",
        "",
        { content: 'Abonos del Periodo', colSpan: 2, styles: { halign: 'left' } },
        "$" + new Intl.NumberFormat("en-US").format(totalAbonosEdoCte),
      ],
      [
        "",
        "",
        { content: 'Saldo Actual', colSpan: 2, styles: { halign: 'left' } },
        "$" + new Intl.NumberFormat("en-US").format(saldoParcial),
      ],
    ],
    showFoot: "lastPage",
  });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: ["Fecha", "Movimiento", "Numero", "Cargo", "Abono"] ,
        sheetHeader: ["Fecha", "Movimiento", "Numero", "Cargo", "Abono"] ,
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  async function jalaMovRel(id, cliente, saldo, email) {
    await axios
      .get(`${URL_ESTADO_CUENTA}Relacionado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setMailTo(email);
    toggleRelacionado();
  }

  function PDFOCRelacionado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    if(saldoFiltroRel == "Pendientes"){
      const data = movimientos.map((a) => {
        if (a.saldo > 1) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.descripcion,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 137, 23, 60, 20);
      var img2 = new Image();
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Descripcion",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            "Cargos",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "Abonos",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "Saldo",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });    
      doc.setFontSize(10);
      doc.text(10, doc.autoTable.previous.finalY + 10, "Datos Bancarios");
      doc.text(10, doc.autoTable.previous.finalY + 15, "Banorte");
      doc.text(10, doc.autoTable.previous.finalY + 20, "Nombre: Cristian Omar Perez Sedas");
      doc.text(10, doc.autoTable.previous.finalY + 25, "Clabe: 072180012996298134");
      doc.text(10, doc.autoTable.previous.finalY + 30, "Cuenta: 01299629813");
      doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
    }else{
      const data = movimientos.map((a) => {
        if (
          (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.descripcion,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Descripcion",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            "Cargos",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "Abonos",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "Saldo",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      doc.setFontSize(10);
      doc.text(10, doc.autoTable.previous.finalY + 10, "Datos Bancarios");
      doc.text(10, doc.autoTable.previous.finalY + 15, "Banorte");
      doc.text(10, doc.autoTable.previous.finalY + 20, "Nombre: Cristian Omar Perez Sedas");
      doc.text(10, doc.autoTable.previous.finalY + 25, "Clabe: 072180012996298134");
      doc.text(10, doc.autoTable.previous.finalY + 30, "Cuenta: 01299629813");
      doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
    }
    
  }


  let totalCargos = 0
  let totalAbonos = 0
  let totalSaldo = 0

  let saldoParcialTabla = saldoInicial
  let totalCargosEdo = 0
  let totalAbonosEdo = 0
  let totalSaldoEdo = 0
 
  let totalCargos2 = 0
  let totalAbonos2 = 0
  let totalSaldo2 = 0

  let saldoParcialTablaRelacionado = 0;
  let totalCargosEdoRelacionado = 0;
  let totalAbonosEdoRelacionado = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_usuarios ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
               
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          
          <h3 align="center">Estado de Cuenta</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalCargos = totalCargos + p.cargos
                totalAbonos = totalAbonos + p.abonos
                totalSaldo = totalSaldo + p.saldo
                {
                  return (
                    <tr>
                      <td> {p.nombre_comercial}</td>
                      <td> {p.ramo}</td>
                      <td>{"$"+new Intl.NumberFormat("en-US").format(p.cargos)}</td>
                      <td>{"$"+new Intl.NumberFormat("en-US").format(p.abonos)}</td>
                      <td>{"$"+new Intl.NumberFormat("en-US").format(p.saldo)}</td>
                      <td>
                      <Button
                              color="info"
                              size="sm"
                              onClick={(e) => jalaMov(
                                  p._id, 
                                  p.cliente,
                                  p.saldo
                                )
                              }
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={(e) => jalaCargos(p._id, p.cliente, p.saldo)}
                              // id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                          color="danger"
                          size="sm"
                          onClick={(e) =>
                            jalaMovRel(p._id, p.cliente, p.saldo, p.email)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td className="negrita" align="right">Totales</td>
                <td className="negrita">{"$"+new Intl.NumberFormat("en-US").format(totalCargos)}</td>
                <td className="negrita">{"$"+new Intl.NumberFormat("en-US").format(totalAbonos)}</td>
                <td className="negrita">{"$"+new Intl.NumberFormat("en-US").format(totalSaldo)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          <Col sm={3}>
            <ButtonGroup>
              <Button size="sm" className="btn" color="danger" 
              onClick={PDFOC}
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              <Button size="sm" className="btn" color="info" 
              onClick={toggleMail2}
              >
                eMail <i class="fas fa-at"></i>
              </Button>
              <Button
                size="sm"
                className="btn"
                color="primary"
                onClick={excelFOC}
              >
                Excel <i class="far fa-file-excel"></i>
              </Button>
            </ButtonGroup>
          </Col> 

          <Col md={3}>
            <Row>
              <Col md={3}>
              <Label className="mr-sm-2">Fecha</Label>
              </Col>
              <Col md={6}>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
              </Col>
            </Row>

          </Col>


        <Col md={3}>
                <h4 align="right">
                              Saldo{"  "}
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(saldo)}
                              </Badge>
                            </h4>
                </Col>
                </Row>
          <div className="card container">
          <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Movimiento
                        </th>
                        <th className="tituloTabla">
                          Numero
                        </th>
                        <th className="tituloTabla">
                          Cargos
                        </th><th className="tituloTabla">
                          Abonos
                        </th>
                        <th className="tituloTabla">
                          Saldo
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {selectedFechaInicio?(
                              <tr>
                              <td align="center">NA</td>
                              <td align="center">Saldo Anterior</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">NA</td>
                              <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoInicial)}</td>
                            </tr>
                        ):undefined}
                      {movimientos
                      .map((c) => {
                        if(
                          (selectedFechaInicio == "" || selectedFechaInicio <= c.fecha)
                          ){
                        saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono
                        totalCargosEdo = totalCargosEdo + c.cargo
                        totalAbonosEdo = totalAbonosEdo + c.abono
                        totalSaldoEdo = totalCargosEdo - totalAbonosEdo
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.movimiento}</td>
                           <td align="center">{c.numero}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.cargo)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.abono)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoParcialTabla)}</td>
                         </tr>
                        )
                      }
                      })}

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Anterior</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoInicial)}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Cargos del Periodo</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargosEdo)}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Abonos del Periodo</td>
                        <td td className="negrita" align="center">{"$-" + new Intl.NumberFormat("en-US").format(totalAbonosEdo)}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td td className="negrita" >Saldo Actual</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoParcialTabla)}</td>
                      </tr>
                    </tbody>
                  </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Estado de Cuenta</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
            <ModalHeader toggle={toggleMail2}>
              <h4>Enviar Estado de Cuenta</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={emailFOC}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          

{/* Estado de Cuenta 2 */}
<Modal size="xxl" isOpen={modalCargos} toggle={toggleCargos}>
        <ModalHeader toggle={toggleCargos}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          {/* <Col sm={3}>
            <ButtonGroup>
              <Button size="sm" className="btn" color="danger" 
              onClick={PDFOC}
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              <Button size="sm" className="btn" color="info" 
              onClick={toggleMail2}
              >
                eMail <i class="fas fa-at"></i>
              </Button>
              <Button
                size="sm"
                className="btn"
                color="primary"
                onClick={excelFOC}
              >
                Excel <i class="far fa-file-excel"></i>
              </Button>
            </ButtonGroup>
          </Col>  */}

        <Col md={3}>
                <h4 align="right">
                              Saldo{"  "}
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(saldo)}
                              </Badge>
                            </h4>
                </Col>
                </Row>
          <div className="card container">
          <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Descripcion
                        </th>
                        <th className="tituloTabla">
                          Cargos
                        </th><th className="tituloTabla">
                          Abonos
                        </th>
                        <th className="tituloTabla">
                          Saldo
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {cargos
                      .map((c) => {
                        totalCargos2 = totalCargos2 + c.cargos
                        totalAbonos2 = totalAbonos2 + c.abonos
                        totalSaldo2 = totalCargos2 - totalAbonos2
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.descripcion}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.importe)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.abonos)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.saldo)}</td>
                         </tr>
                        )
                      })}

                      <tr>
                        <td></td>
                        <td className="negrita" align="center">Totales</td>
                        <td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargos)}</td>
                        <td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalAbonos)}</td>
                        <td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalSaldo)}</td>
                      </tr>

                    </tbody>
                  </Table>
          </div>
        </ModalBody>

        
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size="xxl" isOpen={modalRelacionado} toggle={toggleRelacionado}>
        <ModalHeader toggle={toggleRelacionado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOCRelacionado}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>

          <Row>
              <Col md={3}>
                <Row>
              <Col md={2}>
                  <Label className="mr-sm-2">Saldo</Label>
                  </Col>
              <Col md={10}>
              <Input
                bsSize="sm"
                type="select"
                value={saldoFiltroRel}
                onChange={(e) => {
                  setSaldoFiltroRel(e.target.value);
                }}
              >
                <option value="Pendientes">Pendientes</option>
                <option value="Todas">Todas</option>
              </Input>
                  </Col>
                  </Row>
              </Col>
            </Row>

          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">Fecha</th>
                  <th className="tituloTabla">Descripcion</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                  <th className="tituloTabla">Saldo Total</th>
                </tr>
              </thead>
              <tbody>
              {saldoFiltroRel == "Pendientes" ? (
                  <>
                {movimientos
                .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                .map((c) => {
                  if (c.saldo > 0) {
                    saldoParcialTablaRelacionado = saldoParcialTablaRelacionado + c.saldo;
                    totalCargosEdoRelacionado = totalCargosEdoRelacionado + c.cargo;
                    totalAbonosEdoRelacionado = totalAbonosEdoRelacionado + c.abono;
                    // totalSaldoEdoRelacionado = totalCargosEdoRelacionado - totalAbonosEdoRelacionado;

                      return (
                        // <tr style={{backgroundColor:"#ed8c8c"}}>
                          <tr>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.descripcion}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                c.saldo
                              )}
                          </td>

                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTablaRelacionado
                              )}
                          </td>
                        </tr>
                      )
                  }}
                )}
                  </>

              ): (
                <>
              {movimientos
              .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
              .map((c) => { 
                  saldoParcialTablaRelacionado = saldoParcialTablaRelacionado + c.saldo;
                  totalCargosEdoRelacionado = totalCargosEdoRelacionado + c.cargo;
                  totalAbonosEdoRelacionado = totalAbonosEdoRelacionado + c.abono;
                  // totalSaldoEdoRelacionado = totalCargosEdoRelacionado - totalAbonosEdoRelacionado;

                    return (
                      // <tr style={{backgroundColor:"#ed8c8c"}}>
                        <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.descripcion}</td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                              c.saldo
                            )}
                        </td>

                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                              saldoParcialTablaRelacionado
                            )}
                        </td>
                      </tr>
                    )
                }
              )}
                </>

              )}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Cargos
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoRelacionado)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Abonos
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoRelacionado)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Actual
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleRelacionado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default EstadoCuenta;
